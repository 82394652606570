import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import Header from "./Header/NewHeader";
import Footer from "./footer"
import AOS from "aos";
import { useLocation } from "react-router-dom";
import "aos/dist/aos.css";
import "./index.css";
AOS.init({ once: true });

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: -6,
    height: "100%",
    width: "100%",
    backgroundImage: 'url("https://res.cloudinary.com/dnggzcgt6/image/upload/f_auto,q_auto/v1/saturn/hmsngpz3vbhevwj8khvc")',
    background: "black",
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    // backgroundPosition: 'center center',

  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    backgroundPosition: "unset",
    backgroundSize: "cover",
    minHeight: "100vh",
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",

  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "hidden",
    // padding: "100px 0 0 0",
    [theme.breakpoints.down('sm')]: {
      fontSize: "48px",
    },

  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <div className={classes.root}>
      <div id='stars_copy'></div>
      <div id='stars_copy2'></div>
      <div id='stars_copy3'></div>
      <Header />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
      <Footer />

    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;
