import React, { useState } from "react";
import {
    Box,
    Grid,
    makeStyles, TextField, InputAdornment
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { padding } from "@mui/system";


const useStyles = makeStyles((theme) => ({
    root: {
        position:"relative",
        borderTop: "1px solid #474747",
        background: "#070707",
        padding: "30px 31px 5px",
        [theme.breakpoints.only('xs')]: {
            padding: "10px",
        },
        [theme.breakpoints.only('sm')]: {
            padding: "20px 31px 5px",
        },
        [theme.breakpoints.only('md')]: {
            padding: "30px 31px 5px",
        },
        [theme.breakpoints.only('lg')]: {
            padding: "30px 31px 5px",
        },
    },
    logoSection: {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        [theme.breakpoints.only('xs')]: {
            gap: "10px",
            alignItems: "center"
        },

    },
    logo: {
        width: "100%",
        maxWidth: "251px",
        cursor: "pointer",
        [theme.breakpoints.only('xs')]: {

        },
    },
    logoText: {
        color: "#FFF",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "normal",
        textAlign: "justify",
        textAlign: "left",
        [theme.breakpoints.only('xs')]: {
            textAlign: "center",
        },
    },
    footerHeading: {
        color: "#FFF",
        fontSize: "25px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "normal",
        marginBottom: "30px",
        [theme.breakpoints.only('xs')]: {
            marginBottom: "15px",
        },
    },
    list: {
        listStyleType: "none",
        paddingInlineStart: 0,
        [theme.breakpoints.only('xs')]: {

        },
    },
    listTitle: {
        cursor: "pointer",
        color: "#D6D6D6",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "normal",
        marginBottom: "12px",
        [theme.breakpoints.only('xs')]: {
            marginBottom: 10
        },
    },
    joinSection: {
        display: "flex",
        flexDirection: "column",
        padding: "0px 20px 20px",
        [theme.breakpoints.only('xs')]: {
            padding: 10
        },
    },
    navigateSection: {
        padding: "0px 20px 20px",
        [theme.breakpoints.only('xs')]: {
            padding: 10
        },
    },
    textField: {
        marginTop: "18px",
        borderRadius: "6px",
        border: "1px solid var(--axa, #FDC300)",
        background: "rgba(23, 0, 56, 0.25)",
        [theme.breakpoints.only('xs')]: {

        },
        "& .MuiOutlinedInput-input": {
            color: "white"
        },
        "& .MuiOutlinedInput-adornedEnd": {
            paddingRight: "6px"
        }
    },
    socialIconBox: {
        display: "flex",
        gap: 10,
        justifyContent: "center"
    },
    socialIcon: {
        width: 30,
        height: 30,
        cursor: "pointer"
    },
    rightBox: {
        display: "flex",
        flexDirection: "column",
        gap: 20,
        padding: "50px 0"
    },
    rightReserved: {
        color: "#F6F6F6",
        fontSize: 20,
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "normal"
    },
    horizontalLine: {
        background: "#fff",
        margin: "auto",
        width: "100%",
        maxWidth: 995.061,
        height: 1
    },
    disclaimer: {
        color: "#F6F6F6",
        fontSize: 18,
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "153.094%",
        textAlign: "left"
    }
}));
const useFulLinks = [{ title: "Invest", link: "/invest" }, { title: "Taxation", link: "/taxation" }, { title: "About Us", link: "/about" }, { title: "Recovery", link: "/recovery" }, { title: "Why India?", link: "/why-india" }];
const help = ["info@saturnnri.com", "011-40055400", "+91 9958278620"];
const icon = [
    {
        icon: "/images/linkedIcon.svg",
        link: "linked"
    },
    {
        icon: "/images/xIcon.svg",
        link: "twitter"
    },
    // {
    //     icon: "/images/fbIcon.svg",
    //     link:""
    // },
    {
        icon: "/images/instaIcon.svg",
        link: "insta"
    },
]
function Footer(props) {
    const classes = useStyles();
    const history = useHistory();
    const insta = "https://www.instagram.com/saturnconsultinggroup?igsh=MTJnbzZoOXpxZzd5eg==";
    const linkedin = "https://www.linkedin.com/company/saturn-consulting-group/"
    const twitter = "https://x.com/scg_india1/"
    const redirectToSocial = (link) => {
        if (link == "twitter") {
            window.open(twitter, '_blank');
        }
        else if (link == "linked") {
            window.open(linkedin, '_blank');
        }
        else {
            window.open(insta, '_blank');
        }
    }
    return (
        <>
            <Box className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <Box className={classes.logoSection}>
                            <img className={classes.logo} src="https://res.cloudinary.com/dnggzcgt6/image/upload/v1738481268/saturn/t5zoxgxrsblm2xzouqrv.svg" alt="logo" onClick={() => history.push("/")} />
                            <p className={classes.listTitle}>312-314, Pratap Bhawan,<br /> Bahadur Shah Zafar Marg,<br/> New Delhi, 110002
                            </p>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Box className={classes.navigateSection}>
                            <p className={classes.footerHeading}>Company</p>
                            <ul className={classes.list}>
                                {useFulLinks.map((data) => {
                                    return (
                                        <li onClick={() => history.push(data.link)} className={classes.listTitle}>{data.title}</li>
                                    )
                                })}
                            </ul>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Box className={classes.navigateSection}>
                            <p className={classes.footerHeading}>Contact Us</p>
                            <ul className={classes.list}>
                                {help.map((data) => {
                                    return (
                                        <li className={classes.listTitle}>{data}</li>
                                    )
                                })}
                            </ul>
                            <Box className={classes.socialIconBox}>
                                {icon.map((img) => {
                                    return (
                                        <img src={img.icon} alt="social" className={classes.socialIcon} onClick={() => redirectToSocial(img.link)} />
                                    )
                                })}

                            </Box>
                        </Box>

                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box className={classes.navigateSection}>
                            <p className={classes.footerHeading} onClick={() => history.push("/terms&condition")} style={{ marginBottom: 11, cursor: "pointer" }}>Terms and Conditions</p>
                            <p className={classes.footerHeading} onClick={() => history.push("/privacy-policy")} style={{ marginBottom: 11, cursor: "pointer" }}>Privacy Policy</p>

                        </Box>
                    </Grid>
                    {/* <Grid item xs={12} md={3}>
                        <Box className={classes.joinSection}>
                            <p className={classes.footerHeading}>Social</p>
                            <Box className={classes.socialIconBox}>
                                {icon.map((img) => {
                                    return (
                                        <img src={img.icon} alt="social" className={classes.socialIcon} onClick={() => redirectToSocial(img.link)} />
                                    )
                                })}

                            </Box>
                        </Box>
                    </Grid> */}
                </Grid>

                <Box className={classes.rightBox}>
                    <h3 className={classes.rightReserved}>Saturn NRI. All rights reserved.</h3>
                    <Box className={classes.horizontalLine}></Box>
                </Box>
                <Box>
                    <p className={classes.disclaimer}>
                        About Saturn NRI Private Limited: <br />
                        Saturn NRI Private Limited is a registered company in India having CIN: U69200DL2024PTC426746. Saturn NRI Private Limited is a Mutual Fund distributor with AMFI Registration No: ARN-ABMCS7511E.  It uses BSE Star (with Member code 21765) as a transaction platform.  <br /><br />

                        Our Taxation and Regulatory partners, M/s  S S Dingliwala & Co: Chartered Accountants is one of the oldest Chartered Accountancy firms in India. It is registered with Institute of Chartered Accountants of India having Firm Registration firm: 000799N. The firm has a license to practice Taxation, accounting and Auditing in India  Registered office and Correspondence office- 312-314, Pratap Bhawan, Bahadur Shah Zafar Marg, New Delhi 110002. <br /><br />

                        For any grievances related to any of our services, please write to info@saturnnri.com.  Terms and conditions of the website/app are applicable. Privacy policy of the website is applicable.<br /><br /><br />
                    </p>
                </Box>
            </Box>
        </>
    );
}

export default Footer;
