// Header.js
import React, { useContext, useState } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import "./header.css";
import SideDrawer from "./sidebar";
import { useHistory, Link } from "react-router-dom";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AuthContext } from "src/context/Auth";

const data = [
  { name: "Taxation", id: "", href: "/taxation" },
  // { name: "Recovery", id: "", href: "/recovery" },
  { name: "Why India?", id: "", href: "/why-india" },
  { name: "About Us", id: "", href: "/about" },
];

const selectData = [
  { name: "Investment Plans", href: "/invest" }, 
  { name: "Mutual Fund", href: "/mutual-funds" }, 
  { name: "Demat Account", href: "/demat-account" }, 
  { name: "Corporate Bonds", href: "/corporate-bonds" }, 
  { name: "Government Bonds", href: "/government-bonds" }, 
  { name: "Fixed Deposits", href: "/invest-fd" },
  { name: "LIC", id: "", href: "/lic-policy" },
];

const selectRegulatory = [
  { name: "Demat Account", href: "/demat-account" }, 
  { name: "PAN Card", id: "", href: "/pancard" },
  { name: "Aadhar Card", id: "", href: "/aadhar" },
  { name: "Company Incorporation", id: "", href: "/company-incorporation" },
  { name: "Banking", id: "", href: "/banking" },
  { name: "Share Recovery", id: "", href: "/recovery" }
];

function Header() {
  const Auth = useContext(AuthContext);
  const history = useHistory();
  const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuType, setMenuType] = useState("");

  const isLogin = window.localStorage.getItem("token");

  const handleClick = (event, type) => {
    setAnchorEl(event.currentTarget);
    setMenuType(type);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleSideDrawer = () => {
    setIsSideDrawerOpen(!isSideDrawerOpen);
  };

  const handleMenuItemClick = (href) => {
    handleClose();
    history.push(href);
  };

  const role = window.localStorage.getItem("role");

  return (
    <>
      <header className="header_copy">
        <div className="logo">
          <img src="https://res.cloudinary.com/dnggzcgt6/image/upload/v1738481268/saturn/t5zoxgxrsblm2xzouqrv.svg" alt="Logo" onClick={() => history.push("/")} />
        </div>
        <nav className="nav nav_copy">
          <ul>
            <li style={{ cursor: "pointer", display: "flex", fontWeight: 700 }} onClick={(e) => handleClick(e, "INVEST")}>
              Investment<ExpandMoreIcon />
            </li>
            {data.slice(0, 1).map((item, i) => (
              <li key={i} style={{ cursor: "pointer" }}>
                <Link to={item.href}>{item.name}</Link>
              </li>
            ))}
            <li style={{ cursor: "pointer", display: "flex", fontWeight: 700 }} onClick={(e) => handleClick(e, "TAXATION")}>
              Regulatory<ExpandMoreIcon />
            </li>
            {data.slice(1).map((item, i) => (
              <li key={i + 1} style={{ cursor: "pointer" }}>
                <Link to={item.href}>{item.name}</Link>
              </li>
            ))}
          </ul>
        </nav>
        <div className="buy-button">
          {!isLogin && (
            <>
              <button onClick={() => { Auth.handleAuthOpen(); Auth.setAuthType("LOGIN") }} className="buy-now signin">
                Login
              </button>
              <button onClick={() => { Auth.handleAuthOpen(); Auth.setAuthType("REGISTER") }} className="buy-now signup">
                Register
              </button>
            </>
          )}
          {isLogin && (
            <button onClick={() => history.push(role === "ADMIN" ? "/user-management" : "/dashboard")} className="buy-now signup">
              Dashboard
            </button>
          )}
          <div className="hamburger-css">
            <MenuIcon onClick={toggleSideDrawer} />
          </div>
        </div>
        <SideDrawer isOpen={isSideDrawerOpen} onClose={toggleSideDrawer} />
        <Menu
          style={{ marginTop: "55px" }}
          className="menu"
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {menuType === "INVEST" ? (
            selectData.map((item, index) => (
              <MenuItem key={index} onClick={() => handleMenuItemClick(item.href)}>{item.name}</MenuItem>
            ))
          ) : (
            selectRegulatory.map((item, index) => (
              <MenuItem key={index} onClick={() => handleMenuItemClick(item.href)}>{item.name}</MenuItem>
            ))
          )}
        </Menu>
      </header>
    </>
  );
}

export default Header;
